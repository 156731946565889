import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  FormHelperText,
} from "@mui/material";
import editprofile from "../../assets/Walnut/editprofil.png";
import api from "../../api/tokenCode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./StoreManagement.css";

const EditProductModal = ({ open, handleClose, product }) => {
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");
  const [Description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const fileInputRef = useRef(null);

  // Load product details when the modal opens
  useEffect(() => {
    if (product) {
      setProductName(product.productName || "");
      setPrice(product.price || "");
      setImage(product.productPicter || null);
      setCategory(product.category || ""); // Assuming you have a category field
      setDescription(product.Description || "");
    }
  }, [product]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

//   const handleSaveClick = async () => {
//     const newErrors = {};
//     if (!productName.trim()) newErrors.productName = "Product name is required.";
//     if (!price.trim() || isNaN(price)) newErrors.price = "Valid price is required.";
//     if (Object.keys(newErrors).length) {
//       setErrors(newErrors);
//       return;
//     }

//     setIsSaving(true);

//     try {
//       const payload = {
//         productName,
//         price,
//         category,
//         Description,
//         productPicter: image,
//       };
//       await api.put(`/admin/updateProduct/${product._id}`, payload);
//       handleClose();
//     } catch (error) {
//       console.error("Failed to update product:", error);
//     } finally {
//       setIsSaving(false);
//     }
//   };

const handleSaveClick = async (event) => {
    event.preventDefault();
  
    if (isSaving) return;
    setIsSaving(true);
  
    const newErrors = {};
    if (!productName) newErrors.productName = "* Product name is required.";
    if (!price) newErrors.price = "* Price is required.";
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSaving(false);
      return;
    }
  
    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("price", price);
    formData.append("category", category);
    formData.append("description", Description);
    formData.append("productId", product._id);

    if (fileInputRef.current?.files[0]) {
      formData.append("file", fileInputRef.current.files[0]);
    } else if (image) {
    
      formData.append("productPicter", image);
    }
  
    try {
      
      const response = await api.put('/admin/updateProducts', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Product updated successfully!");
      handleClose(); 
    } catch (error) {
      console.error("Failed to update product:", error);
      toast.error("Failed to update product.");
    } finally {
      setIsSaving(false);
    }
  };
  

  return (
    <Dialog open={open} 
    onClose={handleClose}
    fullWidth
        maxWidth="xl"
        sx={{ marginLeft: "55px" }}
        key={product ? product._id : "new"}
    >
      <DialogTitle>
        <div className="dialog-container">
          <div className="edit-product-section">
            {image ? (
              <img src={image} alt="Selected Product" className="add-image-tag" />
            ) : (
              <img
                src={editprofile}
                alt="Profile"
                style={{ borderRadius: "50px" }}
                className="add-image-tag"

              />
            )}

            <IconButton
              sx={{
                fontWeight: "bold",
                color: "black",
                "&:hover": {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  border: "none",
                },
                "&.MuiButtonBase-root": {
                  transition: "none",
                },
              }}
              onClick={handleIconClick}
              className="add-product-button"
            >
              <span>{"Update Product"}</span>
            </IconButton>

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>

          <div className="modal-button">
            <Button className="save-cancel" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              className="save-cancel"
              onClick={handleSaveClick}
              disabled={isSaving}
            >
              {isSaving ? "Updating..." : "Update"}
            </Button>
          </div>
        </div>

        <div className="modal-input">
          <div style={{ display: "grid" }}>
            <label style={{ fontWeight: "bold" }}>Product name:</label>
            <input
              placeholder="Enter the product"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className="modal-inputfield"
            />
            {errors.productName && (
              <FormHelperText error>{errors.productName}</FormHelperText>
            )}
          </div>

          <div style={{ display: "grid" }} className="price-section">
            <label style={{ fontWeight: "bold" }}>Price:</label>
            <input
              placeholder="Enter the price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="modal-inputfield"
            />
            {errors.price && (
              <FormHelperText error>{errors.price}</FormHelperText>
            )}
          </div>
        </div>

        <div className="modal-category">
          <div style={{ display: "grid" }}>
            <label style={{ fontWeight: "bold" }}>Category:</label>
            <RadioGroup
              row
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <FormControlLabel
                value="Snacks"
                control={<Radio />}
                label="Snacks/Drinks"
              />
              <FormControlLabel
                value="Gears"
                control={<Radio />}
                label="Gears"
              />
            </RadioGroup>
          </div>
        </div>

        <div style={{ padding: "20px" }}>
          <div style={{ display: "grid" }}>
            <label style={{ fontWeight: "bold" }}>Description (optional):</label>
            <input
              placeholder="Description"
              value={Description}
              onChange={(e) => setDescription(e.target.value)}
              className="description-field"
            />
          </div>
        </div>
      </DialogTitle>
    </Dialog>
  );
};


export default EditProductModal;
