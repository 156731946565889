import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./TopNavbar.css";
import avatarIcon from "../../assets/Walnut/avator.png";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import NotificationsSharpIcon from "@mui/icons-material/NotificationsSharp";
import inviteIcon from "../../assets/Walnut/invite-user.svg";
import CreateUser from "../CreateUser/CreateUser";

const TopNavbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    setShowDropdown(false);
    localStorage.removeItem("authToken");
    localStorage.setItem("logoutEvent", Date.now());
    navigate("/");
  };

  const handleInviteClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="top-nav">
      <div>
        <img
          src={inviteIcon}
          alt="invite"
          onClick={handleInviteClick}
          className="avatar"
          style={{ cursor: "pointer" }}
        />
      </div>

      <ul className="user-info">
        <NotificationsSharpIcon sx={{ color: "gray", marginRight: "25px" }} />
        <div className="vl"></div>

        <img src={avatarIcon} alt="Avatar" className="avatar" />

        <button
          className="dropdown-btn"
          onClick={toggleDropdown}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <KeyboardArrowDownSharpIcon sx={{ color: "gray" }} />
        </button>

        {showDropdown && (
          <ul className="dropdown-menu">
            <div className="logout">
              <span onClick={handleLogout}>Logout</span>
            </div>
            <div className="delete">
              <span onClick={handleLogout}>Delete Account</span>
            </div>
          </ul>
        )}
      </ul>

      <CreateUser
        open={open}
        handleClose={handleClose}
        handleLogout={handleLogout}
      />
    </div>
  );
};

export default TopNavbar;
