import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  TablePagination,
} from "@mui/material";
import "./StoreManagement.css";
import add from "../../assets/Walnut/add.svg";
import edit from "../../assets/Walnut/edit.svg";
import Delete from "../../assets/Walnut/delete.svg";
import AddProductModal from "./AddProductModal";
import EditProductModal from "./EditProductModal";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import api from "../../api/tokenCode";
import { toast, ToastContainer } from "react-toastify";

const StoreManagement = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [totalCount, setTotalRows] = useState(0);
  const [productToEdit, setProductToEdit] = useState(null);

  const fetchProducts = async () => {
    try {
      const response = await api.get("/admin/getProducts", {
        params: {
          search: searchTerm,
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        },
      });
      const productList = response.data.list || [];
      setProducts(productList);
      setTotalRows(response.data.count);
    } catch (error) {
      setProducts([]);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (needsRefresh) {
      fetchProducts();
      setNeedsRefresh(false);
    }
  }, [needsRefresh]);

  useEffect(() => {
    fetchProducts();
  }, [page, rowsPerPage, searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(0);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setNeedsRefresh(true);
  };

  const handleOpenEditModal = (product) => {
    setProductToEdit(product);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setProductToEdit(null);
    setNeedsRefresh(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Delete Product Function
  const handleDeleteProduct = async (id) => {
    try {
      // API Request to delete the product
      const response = await api.delete(`/admin/deleteProducts/${id}`);
  
      // If deletion is successful
      if (response.status === 200) {
        // Update the list by removing the deleted product
        setProducts((prevProducts) => prevProducts.filter((product) => product._id !== id));
        setNeedsRefresh(true); // Trigger refresh to update the UI
  
        // Show a success toast
        toast.success("Product deleted successfully!", {
          position: "top-right", // Use a string instead of POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      console.error("Error deleting product:", error);
  
      // Show an error toast
      toast.error("Failed to delete product.", {
        position: "top-right",
      });
    }
  };
  
  const tableHeaderCellStyle = {
    color: "#000",
    fontWeight: "bold",
    padding: "20px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#F6F8FA",
    textAlign: "center",
    fontSize: "18px",
  };

  const tableCellStyle = {
    padding: "10px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    textAlign: "center",
    fontSize: "15px",
  };

  return (
    <Box sx={{ marginLeft: 5 }}>
      <LeftSidenav />
      <TopNavbar />
      <Box className="store-padding-container">
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA" }}>
          <Table>
            <div className="store-header">
              <h2>Store Management</h2>
              <div style={{ paddingRight: "15px" }}>
                <Button
                  className="add-btn"
                  sx={{
                    color: "white",
                    backgroundColor: "Black",
                    gap: "10px",
                    border: "1px solid #ccc",
                    height: "40px",
                    width: "125px",
                    padding: "8px",
                  }}
                  onClick={handleOpenAddModal}
                >
                  Add New
                  <img src={add} alt="add" />
                </Button>
              </div>
            </div>
          </Table>
          <hr />

          <Table className="store-table">
            <div style={{ paddingLeft: "20px" }}>
              <div className="store-filters">
                <input
                  name="search"
                  className="store-search-text"
                  placeholder="Search by Product name"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </Table>

          <Table
            sx={{
              minWidth: 650,
              borderCollapse: "separate",
              borderSpacing: "0 20px",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderCellStyle}>Product Image</TableCell>
                <TableCell sx={tableHeaderCellStyle}>Product Name</TableCell>
                <TableCell sx={tableHeaderCellStyle}>Price</TableCell>
                <TableCell sx={tableHeaderCellStyle}>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {products.length > 0 ? (
                products.map((product) => (
                  <TableRow key={product._id}>
                    <TableCell sx={tableCellStyle}>
                      <img
                        src={product.productPicter}
                        alt={product.productName}
                        style={{ width: "50px" }}
                      />
                    </TableCell>
                    <TableCell
                      sx={tableCellStyle}
                      className="capitalize-product"
                    >
                      {product.productName}
                    </TableCell>
                    <TableCell sx={tableCellStyle}>{product.price}</TableCell>
                    <TableCell sx={tableCellStyle}>
                      <img
                        src={edit}
                        alt="Edit"
                        onClick={() => handleOpenEditModal(product)}
                        style={{ cursor: "pointer", marginRight: "10px" }}
                      />
                      <img
                        src={Delete}
                        alt="Delete"
                        onClick={() => handleDeleteProduct(product._id)} // Use product._id here
                        style={{ cursor: "pointer" }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{
                      textAlign: "center",
                      padding: "20px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={products.length > 0 ? totalCount : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <AddProductModal open={openAddModal} handleClose={handleCloseAddModal} />
        <EditProductModal
          open={openEditModal}
          handleClose={handleCloseEditModal}
          product={productToEdit}
        />
      </Box>
    </Box>
  );
};


export default StoreManagement;
