import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  CircularProgress,
} from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import api from "../../api/tokenCode";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ExportButton from "./ExportButton";
import "./SchedulingManagement.css";

const ScheduleManagement = () => {
  const [calendarView, setCalendarView] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    simulatorBay: "",
  });

  const [viewDates, setViewDates] = useState({
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
  });

  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState("month");

  const localizer = momentLocalizer(moment);

  const fetchBookings = async (startDate, endDate) => {
    setLoading(true);
    try {
      const response = await api.get("/booking/getBookingsforAdmin", {
        params: { startDate, endDate },
      });
      setBookings(response.data.list || []);
    } catch (err) {
      setError("Error fetching bookings");
    } finally {
      setLoading(false);
    }
  };

  const handleNavigate = (date) => {
    let startDate, endDate;

    setCurrentDate(date);

    if (currentView === "month") {
      startDate = moment(date).startOf("month").format("YYYY-MM-DD");
      endDate = moment(date).endOf("month").format("YYYY-MM-DD");
    } else if (currentView === "week") {
      startDate = moment(date).startOf("week").format("YYYY-MM-DD");
      endDate = moment(date).endOf("week").format("YYYY-MM-DD");
    } else if (currentView === "day") {
      startDate = moment(date).startOf("day").format("YYYY-MM-DD");
      endDate = moment(date).endOf("day").format("YYYY-MM-DD");
    }

    setViewDates({ startDate, endDate });
    fetchBookings(startDate, endDate);
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
    handleNavigate(currentDate);
  };

  useEffect(() => {
    fetchBookings(viewDates.startDate, viewDates.endDate);
  }, []);

  const handleCalendarViewToggle = (event) => {
    setCalendarView(event.target.checked);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const filteredSchedules =
    bookings.filter((row) => {
      const fullName = `${row.firstName} ${row.lastName}`;
      return (
        fullName.toLowerCase().includes(filter.search.toLowerCase()) &&
        (filter.simulatorBay ? row.simulatorBay === filter.simulatorBay : true)
      );
    }) || [];

  const tableHeaderCellStyle = {
    color: "#000",
    fontWeight: "bold",
    padding: "20px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#F6F8FA",
    textAlign: "center",
    fontSize: "18px",
  };

  const tableCellStyle = {
    padding: "10px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    textAlign: "center",
    fontSize: "15px",
  };

  return (
    <Box sx={{ marginLeft: 5 }}>
      <LeftSidenav />
      <TopNavbar />

      <Box className="padding-container">
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA" }}>
          <Table>
            <div className="header">
              <h2>Schedule Management</h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                <span style={{ marginRight: "10px" }}>Calendar View</span>
                <Switch
                  checked={calendarView}
                  onChange={handleCalendarViewToggle}
                  style={{ marginRight: "10px" }}
                />
                <ExportButton />
              </div>
            </div>
          </Table>
          <hr />

          <div id="export">
            <Table className="schedule-table">
              <div style={{ paddingLeft: "20px" }}>
                <div className="schedule-filters">
                  {!calendarView && (
                    <input
                      name="search"
                      className="schedule-search-text"
                      placeholder="Search by name"
                      value={filter.search}
                      onChange={handleFilterChange}
                      variant="outlined"
                      size="small"
                      sx={{ marginRight: 1 }}
                    />
                  )}
                </div>
              </div>
            </Table>

            {loading ? (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <CircularProgress />
              </div>
            ) : error ? (
              <div
                style={{ textAlign: "center", marginTop: "20px", color: "red" }}
              >
                {error}
              </div>
            ) : calendarView ? (
              <div className="calendar-container">
                <Calendar
                  localizer={localizer}
                  events={bookings.map((row) => ({
                    title: `${row.firstName} ${row.lastName}`,
                    start: new Date(row.startTime),
                    end: new Date(row.endTime),
                    bayNumber: row.bayNumber || "N/A",
                  }))}
                  startAccessor="start"
                  endAccessor="end"
                  date={currentDate}
                  view={currentView}
                  onNavigate={handleNavigate}
                  onView={handleViewChange}
                  style={{ height: 500, margin: "20px" }}
                  titleAccessor="title"
                  views={["month", "week", "day"]}
                  defaultView="month"
                  components={{
                    month: {
                      event: ({ event }) => (
                        <span>
                          {new Date(event.start).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}{" "}
                          -
                          {new Date(event.end).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                          <br />
                          {event.title}
                          <br />
                          Bay: {event.bayNumber}
                        </span>
                      ),
                    },
                    week: {
                      event: ({ event }) => (
                        <span style={{ fontSize: "13px" }}>
                          <div style={{ marginBottom: "7px" }}>  {event.title}</div>
                          <div >
                            Bay: {event.bayNumber}
                          </div>
                        </span>
                      ),
                    },
                    day: {
                      event: ({ event }) => (
                        <span style={{ fontSize: "13px" }}>
                          <div style={{ marginBottom: "7px" }}>
                            {event.title}
                          </div>
                          <div>Bay: {event.bayNumber}</div>
                        </span>
                      ),
                    },
                  }}
                />
                {loading && <p>Loading bookings...</p>}
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>
            ) : (
              <TableContainer
                component={Paper}
                sx={{ backgroundColor: "#F6F8FA" }}
              >
                <Table
                  sx={{
                    minWidth: 650,
                    borderCollapse: "separate",
                    borderSpacing: "0 20px",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={tableHeaderCellStyle}>Name</TableCell>
                      <TableCell sx={tableHeaderCellStyle}>
                        Booking Date
                      </TableCell>
                      <TableCell sx={tableHeaderCellStyle}>Time Slot</TableCell>
                      <TableCell sx={tableHeaderCellStyle}>
                        Simulator Bay
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredSchedules.length > 0 ? (
                      filteredSchedules.map((schedule) => (
                        <TableRow key={schedule._id}>
                          <TableCell sx={tableCellStyle}>
                            {`${schedule.firstName} ${schedule.lastName}`}
                          </TableCell>
                          <TableCell sx={tableCellStyle}>
                            {moment(schedule.startTime).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell sx={tableCellStyle}>
                            {moment(schedule.startTime).format(" HH:mm -")}
                            {moment(schedule.endTime).format(" HH:mm")}
                          </TableCell>
                          <TableCell sx={tableCellStyle}>
                            <span>Bay- {schedule.bayNumber || "N/A"}</span>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          sx={{
                            textAlign: "center",
                            padding: "20px",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          No schedules found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ScheduleManagement;
