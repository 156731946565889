import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CreateUser.css";
import api from "../../api/tokenCode";

const CreateUser = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "",
    subscriptionId: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const adminUserId = localStorage.getItem("userId");
  const [paddingActive, setPaddingActive] = useState(false);

  console.log(adminUserId); // See what the value is before parsing

  console.log(adminUserId, "tett");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear error when user types in the field
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.firstName) {
      newErrors.firstName = "First Name is required";
      isValid = false;
    }
    if (!formData.lastName) {
      newErrors.lastName = "Last Name is required";
      isValid = false;
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
      isValid = false;
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }
    if (!formData.role) {
      newErrors.role = "Role is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (validateForm()) {
  //     setIsSubmitting(true);

  //     try {
  //       const response = await api.post("/admin/createUser", {
  //         firstName: formData.firstName,
  //         lastName: formData.lastName,
  //         email: formData.email,
  //         password: formData.password,
  //         role:
  //           formData.role === "admin"
  //             ? "A"
  //             : formData.role === "user"
  //             ? "U"
  //             : "FU",
  //         subscriptionId: formData.subscriptionId || "",
  //         userId: adminUserId,
  //         // userId:"675960f366c4582899a5940b"
  //       });

  //       if (response.status === 200) {
  //         resetForm();
  //         handleClose();
  //         toast.success("User created successfully!");
  //       } else {
  //         throw new Error("Failed to create user");
  //       }
  //     } catch (error) {
  //       console.error(error.response?.data?.message || error.message);
  //       toast.error(error.response?.data?.message || "Failed to create user");
  //     } finally {
  //       setIsSubmitting(false);
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPaddingActive(true);  // Add padding when submit is clicked
    
    if (validateForm()) {
      setIsSubmitting(true);
  
      try {
        const response = await api.post("/admin/createUser", {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          role: formData.role === "admin" ? "A" : formData.role === "user" ? "U" : "FU",
          subscriptionId: formData.subscriptionId || "",
          userId: adminUserId,
        });
  
        if (response.status === 200) {
          resetForm();
          handleClose();
          toast.success("User created successfully!");
        } else {
          throw new Error("Failed to create user");
        }
      } catch (error) {
        console.error(error.response?.data?.message || error.message);
        toast.error(error.response?.data?.message || "Failed to create user");
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      role: "",
      subscriptionId: "",
    });
    setErrors({});
  };

  const handleCloseWithReset = () => {
    resetForm();
    handleClose();
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />{" "}
      {/* Add ToastContainer */}
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason === "backdropClick") return;
          handleCloseWithReset();
        }}
        fullWidth
        maxWidth="lg"
        sx={{
          "& .MuiDialog-paper": {
            overflowX: "hidden",
          },
        }}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(),
        }}
      >
        <DialogTitle
          sx={{ backgroundColor: "black", color: "white", padding: "16px" }}
        >
          Create an User
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseWithReset}
            aria-label="close"
            sx={{ position: "absolute", right: 20, top: 8, color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <span className="inline-container">
            <p>Please fill in the details to create new user</p>
          </span>

          <form onSubmit={handleSubmit} className="createForm">
            <div
              className="input-container"
              style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}
            >
              <div style={{ flex: 1 }}>
                <label
                  style={{
                    fontSize: "19px",
                    fontWeight: "400",
                    display: "block",
                    marginBottom: "0px",
                  }}
                >
                  First Name<span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  placeholder="First Name"
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  margin="dense"
                  required
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  sx={{
                    "& input": {
                      textTransform: "capitalize",
                    },
                  }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <label
                  style={{
                    fontSize: "19px",
                    fontWeight: "400",
                    display: "block",
                    marginBottom: "1px",
                  }}
                >
                  Last Name<span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  placeholder="Last Name"
                  variant="outlined"
                  fullWidth
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  margin="dense"
                  required
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  sx={{
                    "& input": {
                      textTransform: "capitalize",
                    },
                  }}
                />
              </div>
            </div>
            <div>
              <label
                style={{
                  fontSize: "19px",
                  fontWeight: "400",
                  display: "block",
                  marginBottom: "0px",
                  marginTop: "10px",
                }}
              >
                Email Address<span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                placeholder="Email Address"
                variant="outlined"
                fullWidth
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                margin="dense"
                required
                error={!!errors.email}
                helperText={errors.email}
              />
            </div>
            <div>
              <label
                style={{
                  fontSize: "19px",
                  fontWeight: "400",
                  display: "block",
                  marginBottom: "0px",
                  marginTop: "10px",
                }}
              >
                Set Password<span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                placeholder="Set Password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                fullWidth
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                margin="dense"
                required
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div
              className="input-container-1"
              style={{ display: "flex", gap: "20px" }}
            >
              <div style={{ flex: 1 }}>
                <label
                  style={{
                    fontSize: "19px",
                    fontWeight: "400",
                    display: "block",
                    marginBottom: "0px", // Uniform margin
                    marginTop: "10px", // Uniform margin
                  }}
                >
                  Role<span style={{ color: "red" }}>*</span>
                </label>
                <FormControl
                  fullWidth
                  required
                  sx={{ marginTop: "8px", marginBottom: "0px" }}
                >
                  <Select
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                    displayEmpty
                    error={!!errors.role} 
                  >
                    <MenuItem value="" disabled>
                      Select Role
                    </MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="user">Standard User</MenuItem>
                    <MenuItem value="guest">Free Unlimited User</MenuItem>
                  </Select>
                  {errors.role && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "4px",
                      }}
                    >
                      {errors.role} {/* Show the error message */}
                    </div>
                  )}
                </FormControl>
              </div>

              {formData.role !== "admin" && formData.role !== "guest" && (
                // <div style={{ flex: 1 }}>
                //   <label
                //     style={{
                //       fontSize: "19px",
                //       fontWeight: "400",
                //       display: "block",
                //       marginBottom: "0px", // Uniform margin
                //       marginTop: "10px", // Uniform margin
                //     }}
                //   >
                //     Subscription ID
                //   </label>
                //   <TextField
                //     placeholder="Subscription ID"
                //     variant="outlined"
                //     fullWidth
                //     name="subscriptionId"
                //     value={formData.subscriptionId}
                //     onChange={handleInputChange}
                //     margin="dense"
                //     sx={{
                //       marginTop: "8px", // Align with the dropdown above
                //       marginBottom: "0px",
                //     }}
                //   />
                // </div>

                <div style={{ flex: 1 }}>
  <label
    style={{
      fontSize: "19px",
      fontWeight: "400",
      display: "block",
      marginBottom: "0px",
      marginTop: "10px",
    }}
  >
    Subscription ID
  </label>
  <TextField
    placeholder="Subscription ID"
    variant="outlined"
    fullWidth
    name="subscriptionId"
    value={formData.subscriptionId}
    onChange={handleInputChange}
    margin="dense"
    sx={{
      marginTop: "8px",
      marginBottom: "0px",
      paddingBottom: paddingActive ? "23px" : "0px", // Conditional padding
    }}
  />
</div>

              )}
            </div>
          </form>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "#F6F8FA",
            padding: "16px",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <button
            variant="outlined"
            color="secondary"
            onClick={handleCloseWithReset}
            className="create-cancel"
          >
            Cancel
          </button>
          <button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="create-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={24} color="secondary" />
            ) : (
              "Create User"
            )}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateUser;
