import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import api from "../../api/tokenCode";
import "./MemberManagement.css";
import JoinDate from "../../assets/Walnut/joindate.svg";
import plan from "../../assets/Walnut/plan.svg";
import email from "../../assets/Walnut/email.svg";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import CloseIcon from "@mui/icons-material/Close";

const getInitials = (name) => {
  const names = name.split(" ");
  return names.length > 1
    ? names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase()
    : names[0].charAt(0).toUpperCase();
};

const MemberManagement = () => {
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    plan: "",
    joinDate: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchSubscribedUsers = async () => {
      try {
        const skip = page * rowsPerPage;
        const limit = rowsPerPage;
        const response = await api.get("/admin/getSubscribedUsers", {
          params: {
            search: filter.search,
            limit: limit,
            skip: skip,
          },
        });
        const data = response.data.list;
        setMembers(data);
        setTotalCount(response.data.count);
      } catch (error) {}
    };

    fetchSubscribedUsers();
  }, [page, rowsPerPage, filter.search]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleEditMember = (member, e) => {
    if (e && typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
    setSelectedMember(member);
  };

  const handleCloseDetails = (e) => {
    if (e && typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
    setSelectedMember(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ProfileImage = ({ name }) => {
    const initials = getInitials(name);

    return <div className="profile-image">{initials}</div>;
  };

  const tableHeaderCellStyle = {
    color: "#000",
    fontWeight: "bold",
    padding: "20px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#F6F8FA",
    textAlign: "center",
    fontSize: "18px",
  };

  const tableCellStyle = {
    padding: "10px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    textAlign: "center",
    fontSize: "15px",
    cursor: "pointer",
  };

  return (
    <Box sx={{ marginLeft: 5 }}>
      <LeftSidenav />
      <TopNavbar />
      <Box className="padding-container">
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA" }}>
          <Table>
            <div className="header">
              <h2>Members Management</h2>
            </div>
          </Table>
          <hr />
          <Table>
            <div style={{ paddingLeft: "20px" }}>
              <div
                className="filters"
                style={{ marginBottom: "16px", marginTop: "20px" }}
              >
                <input
                  name="search"
                  className="search-textj"
                  placeholder="Search by name & email "
                  value={filter.search}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
          </Table>

          <Table
            sx={{
              minWidth: 650,
              borderCollapse: "separate",
              borderSpacing: "0 20px",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderCellStyle}>Name</TableCell>
                <TableCell sx={tableHeaderCellStyle}>Email Address</TableCell>
                <TableCell sx={tableHeaderCellStyle}>Join Date</TableCell>
                <TableCell sx={tableHeaderCellStyle}>
                  Subscription Plan
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {members.length > 0 ? (
                members.map((member) => (
                  <>
                    <TableRow onClick={(e) => handleEditMember(member, e)}>
                      <TableCell sx={tableCellStyle} className="capitalize">
                        {member.firstName}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>{member.email}</TableCell>
                      <TableCell sx={tableCellStyle}>
                        {new Date(member.createdAt)
                          .toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                          .replace(/\//g, "-")}
                      </TableCell>
                      {/* <TableCell sx={tableCellStyle} className="capitalize">
                        {member.SubscriptionPlan}
                      </TableCell> */}

                      <TableCell sx={tableCellStyle} className="capitalize">
                        {member.SubscriptionPlan.replace(/([a-zA-Z])([0-9])/g,"$1 $2")
                          .replace(/([0-9])([a-zA-Z])/g, "$1 $2")
                          .replace(/([a-zA-Z])([A-Z])/g, "$1 $2")
                          .replace(/_/g, "-")
                          .replace(/-/g, " - ")}
                      </TableCell>
                    </TableRow>

                    {selectedMember && selectedMember.userId === member.userId && (
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          sx={{ backgroundColor: "#EEF0F1", padding: "20px" }}
                        >
                          <div
                            className="member-details"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <CloseIcon
                              onClick={(e) => handleCloseDetails(e)}
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                cursor: "pointer",
                              }}
                            />
                            <div className="selectmemSection">
                              <div
                                className="profile"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <ProfileImage
                                  name={`${selectedMember.firstName}`}
                                />
                                <div>
                                  <h3 className="capitalize">
                                    {selectedMember.firstName}
                                  </h3>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={email}
                                      alt="email"
                                      style={{
                                        marginRight: "10px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                    <p className="member-p-tag">
                                      {selectedMember.email}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="member-meta">
                                <div
                                  style={{
                                    backgroundColor: "#EEF0F1",
                                    padding: "10px",
                                  }}
                                >
                                  <img src={JoinDate} alt="joindate" />
                                  <ul className="dash-ul">
                                    <li>Join Date:</li>
                                    <li>
                                      {new Date(selectedMember.createdAt)
                                        .toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })
                                        .replace(/\//g, "-")}
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#EEF0F1",
                                    padding: "10px",
                                  }}
                                >
                                  <img src={plan} alt="plan" />
                                  <ul className="dash-ul">
                                    <li>Current Plan:</li>
                                    <li className="">
                                      {selectedMember.SubscriptionPlan}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{
                      textAlign: "center",
                      padding: "20px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50,100]}
            component="div"
            count={totalCount} 
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={members.length > 0 ? totalCount : 0} // Show count as 0 if no members
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default MemberManagement;
