import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  FormHelperText,
} from "@mui/material";
// import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import editprofile from "../../assets/Walnut/editprofil.png";
import api from "../../api/tokenCode";
import { useNavigate } from "react-router-dom";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./StoreManagement.css";

const AddProductModal = ({ open, handleClose, product }) => {
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [stockStatus, setStockStatus] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false); 
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (product) {
      setProductName(product.name);
      setPrice(product.price);
      setCategory(product.category);
      setStockStatus(product.stockStatus);
      setDescription(product.description || "");
      setImage(product.image || null);
    } else {
      setProductName("");
      setPrice("");
      setCategory("");
      setStockStatus("");
      setDescription("");
      setImage(null);
    }
  }, [product, open]); 

  const handleSaveClick = async (event) => {
    event.preventDefault();

    // Prevent multiple submissions if already saving
    if (isSaving) return;

    setIsSaving(true);  // Disable button

    setErrors({});
    const newErrors = {};
    if (!productName) {
      newErrors.productName = "* Product name is required.";
    }
    if (!price) {
      newErrors.price = "* Price is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSaving(false);  // Re-enable button in case of errors
      return;
    }

    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("price", price);
    formData.append("category", category);
    formData.append("stockStatus", stockStatus);
    formData.append("Description", description);
    if (fileInputRef.current.files[0]) {
      formData.append("file", fileInputRef.current.files[0]);
    }

    try {
      const response = await api.post("/admin/createProducts", formData);
      toast.success("Product saved successfully!");
      handleClose();
      setTimeout(() => {
        console.log("Navigating to /store");
        navigate("/store");
      }, 1500);
    } catch (error) {
      // Handle error (you may want to toast an error message here)
      console.error("Error saving product:", error);
    } finally {
      setIsSaving(false);  // Re-enable button after API call is complete
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleDialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setImage(null);
      setErrors({});
      handleClose();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="xl"
        sx={{ marginLeft: "55px" }}
        key={product ? product._id : "new"} 
      >
        <DialogTitle>
          <div className="dialog-container">
            <div className="edit-product-section">
              {image ? (
                <img
                  src={image}
                  alt="Selected Product"
                  className="add-image-tag"
                />
              ) : (
                <img
                  src={editprofile}
                  alt="Profile"
                  style={{ borderRadius: "50px" }}
                />
              )}

              <IconButton
                sx={{
                  fontWeight: "bold",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "transparent",
                    boxShadow: "none", // Remove any hover shadow
                    border: "none", // Remove any hover border
                  },
                  "&.MuiButtonBase-root": {
                    transition: "none", // Disable MUI's default hover transition effect
                  },
                }}
                onClick={handleIconClick}
                className="add-product-button"
              >
                <span>{"Add New Product"}</span>
              </IconButton>

              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>

            <div className="modal-button">
              <Button className="save-cancel" onClick={handleDialogClose}>
                Cancel
              </Button>

              <Button
                className="save-cancel"
                onClick={handleSaveClick}
                disabled={isSaving} // Disable button if saving
              >
                {isSaving ? "Saving..." : "Save"}
              </Button>
            </div>
          </div>

          <div className="modal-input">
            <div style={{ display: "grid" }}>
              <label style={{ fontWeight: "bold" }}>Product name:</label>
              <input
                placeholder="Enter the product"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                className="modal-inputfield"
              />
              {errors.productName && (
                <FormHelperText error>{errors.productName}</FormHelperText>
              )}
            </div>

            <div style={{ display: "grid" }} className="price-section">
              <label style={{ fontWeight: "bold" }}>Price:</label>
              <input
                placeholder="Enter the price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="modal-inputfield"
              />
              {errors.price && (
                <FormHelperText error>{errors.price}</FormHelperText>
              )}
            </div>
          </div>

          <div className="modal-category">
            <div style={{ display: "grid" }}>
              <label style={{ fontWeight: "bold" }}>Category:</label>
              <RadioGroup
                row
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <FormControlLabel
                  value="Snacks"
                  control={<Radio />}
                  label="Snacks/Drinks"
                />
                <FormControlLabel
                  value="Gears"
                  control={<Radio />}
                  label="Gears"
                />
              </RadioGroup>
            </div>
          </div>

          <div style={{ padding: "20px" }}>
            <div style={{ display: "grid" }}>
              <label style={{ fontWeight: "bold" }}>Description (optional):</label>
              <input
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="description-field"
              />
            </div>
          </div>
        </DialogTitle>
      </Dialog>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default AddProductModal;
